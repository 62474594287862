import axios from 'axios';
import { API_BASE_URL, getOrgId } from '../constants';

export function getUserProfile(){
    return axios.get(`${API_BASE_URL}/app/rest/profile`, ).then(res => res.data).catch(error => console.log(error));
}

export function updateOrg(){
    return axios.post(`${API_BASE_URL}/app/rest/profile/update_org`, null, {
        params: {orgId: getOrgId()}
    }).then(res => res.data).catch(error => console.log(error));
}

export function updateUser(data){
    return axios.put(`${API_BASE_URL}/app/rest/profile`, data, {  
        headers: {
            'Content-Type': 'multipart/form-data' 
        }
    }).then(res => res.data).catch(error => console.log(error));
}

export function changePassword(data){
    return axios.post(`${API_BASE_URL}/app/rest/profile/change_password`, data).then(res => res.data).catch(error => console.log(error));
}
