// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_BASE_URL = 'https://seeknspot.com'

let loginURL = '';
if (process.env.REACT_APP_LOGIN_BASE_URL) {
  loginURL = process.env.REACT_APP_LOGIN_BASE_URL;
} else {
  loginURL = process.env.REACT_APP_API_BASE_URL;
};
export const LOGIN_BASE_URL = loginURL;

let residenceURL = '';
if (process.env.REACT_APP_RESIDENCE_BASE_URL) {
  residenceURL = process.env.REACT_APP_RESIDENCE_BASE_URL;
} else {
  residenceURL = process.env.REACT_APP_API_BASE_URL;
};
export const RESIDENCE_BASE_URL = residenceURL;

let formURL = '';
if (process.env.REACT_APP_FORM_BASE_URL) {
  formURL = process.env.REACT_APP_FORM_BASE_URL;
} else {
  formURL = process.env.REACT_APP_API_BASE_URL;
};

let snsURL = '';
if (process.env.REACT_APP_SNS_BASE_URL) {
  snsURL = process.env.REACT_APP_SNS_BASE_URL;
} else {
  snsURL = process.env.REACT_APP_API_BASE_URL;
};

let mailServiceUrl = '';
if (process.env.REACT_APP_MAIL_BASE_URL) {
  mailServiceUrl = process.env.REACT_APP_MAIL_BASE_URL;
} else {
  mailServiceUrl = process.env.REACT_APP_API_BASE_URL;
};
export const MAIL_BASE_URL = mailServiceUrl;

export const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL;
export const WS_FALLBACK_BASE_URL = process.env.REACT_APP_WS_FALLBACK_BASE_URL;

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_CREDENTIALS = process.env.REACT_APP_CLIENT_CREDENTIALS;
export const TENANT = process.env.REACT_APP_TENANT;

export const SCP_PATH = process.env.REACT_APP_PATH_SCP;
export const RESIDENCE_PATH = process.env.REACT_APP_PATH_RESIDENCE;
export const MAIL_PATH = process.env.REACT_APP_PATH_MAIL;
export const FORM_PATH = process.env.REACT_APP_PATH_FORM;
export const SNS_PATH = process.env.REACT_APP_PATH_SNS;

export const FORM_BASE_URL = formURL + FORM_PATH;
export const SNS_BASE_URL = snsURL + SNS_PATH;

export const APPLICATION_FORM_VIEW_URL = process.env.REACT_APP_APPLICATION_FORM_VIEW_URL;

export function getTenantAlias() {
  let parts = window.location.host.split(".");
  let subdomain = "ymca";
  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  if (parts.length >= 3) {
      subdomain = parts[0];
  }
  return subdomain.split("-")[0];
};

export const MESSAGE = {
  ERROR_FIELD_INPUT_REQUIRED: "Field require input",
  ERROR_FIELD_SELECT_REQUIRED: "Please select a value",
  INVALID_NUMBER_FORMAT: "Wrong number",
  INVALID_EMAIL_FORMAT: "Wrong email format",
  DUPLICATED_VALUE: "This value has existed"
};

export const TABLE_PAGING_LENGTH = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 }
];

export const PROG_STATUSES = [
  { label: "All", value: "" },
  { label: "Active", value: true },
  { label: "Not Active", value: false }
];

export const PROG_WORK_TYPES = [
  { label: "Simple", value: "simple" },
  { label: "Daily/Weekly", value: "dw" }
];

export const NAME_TITLES = [
  { label: "Not use", value: "" },
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
  { label: "Dr.", value: "Dr." }
];

export const GENDERS = [
  { label: "Any", value: "Any" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Non-binary", value: "NonBinary" }
];

export const GL_TYPES = [
  { label: "Cash", value: "Cash" },
  { label: "Receivable", value: "Receivable" },
  { label: "Deferred", value: "Deferred" },
  { label: "Program", value: "Program" },
  { label: "Membership", value: "Membership" },
  { label: "Assistance", value: "Assistance" }
];

export const FILTER_GROUP_TYPES = [
  { label: "All", value: "" },
  { label: "Household", value: "household" },
  { label: "Company", value: "company" }
];

export const GROUP_TYPES = [
  { label: "Household", value: "household" },
  { label: "Company", value: "company" }
];

export const GROUP_MEMBER_RELATIONSHIP_TYPES = [
  { label: "Grand", value: "Grand" },
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Child", value: "Child" }
];

export const USER_RELATIONSHIP_TYPES = [
  { label: "Friend", value: "friend" },
  { label: "Family", value: "family" },
  { label: "Co-worker", value: "coworker" },
  { label: "Parent", value: "parent" },
  { label: "Father", value: "father" },
  { label: "Mother", value: "mother" },
  { label: "Child", value: "child" },
  { label: "Son", value: "son" },
  { label: "Daughter", value: "daughter" }
];

export const USER_KIND_OF_RELATIONSHIPS = [
  { label: "Emergency", value: "emergency" },
  { label: "Pickup", value: "pickup" },
  { label: "Payor", value: "payor" },
];

export const FIELD_TYPE = {
  text: "text",
  number: "number",
  email: "email",
  phone: "phone",
  select: "select",
  radio: "radio",
  checkbox: "checkbox",
  boolean: "yes_no",
  textarea: "textarea",
  file: "file",
  date: "date",
  waiver: "waiver"
};

export const FIELD_EXPRESSION_TYPE = {
  none: 0,
  pattern: 1,
  maskString: 2
};

export const FIELD_DISPLAY_TYPE = {
  dropdown: "dropdown",
  checkbox: "checkbox",
  radio: "radio",
  textbox: "textbox",
  file: "file"
};

export const ENCRYPTED_OPTIONS = [
  { label: "UNENCRYPTED", value: "Unencrypted" },
  { label: "SSL/TLS", value: "SSLTLS" },
  { label: "STARTTLS", value: "STARTTLS" }
];

export const PAYMENT_PLAN_TYPE = {
  full: "full",
  recurring: "recurring"
};

export const PAYMENT_GATEWAY = {
  OFFLINE: "OFFLINE",
  STRIPE: "STRIPE",
  BRAINTREE: "BRAINTREE",
  SLIM_CD: "SLIM_CD",
  LUMINATE_DONATION_API: "LUMINATE_DONATION_API",
  RED_DOT: "RED_DOT",
  FREE: "FREE",
  USER_CREDIT: "USER_CREDIT"
};

export const PAYMENT_METHOD = {
  Cash: "Cash",
  CreditCard: "CreditCard",
  UserCredit: "UserCredit"
};

export const ORDER_STATE = {
  cart: 'cart',
  new_order: 'new_order',
  viewed: 'viewed',
  canceled: 'canceled'
};

export const PAYMENT_STATE = {
  pending: "pending",
  awaiting: "awaiting",
  completed: "completed"
};

export const CHANNEL_TYPE = {
  event: "event",
  program: "program",
  store: "store",
  membership: "membership",
  donation: "donation"
};

export const PRODUCT_TYPE = {
  personalTicket: "personal_ticket",
  groupPersonalTicket: "group_personal_ticket",
  normalTicket: "normal_ticket",
  groupNormalTicket: "group_normal_ticket",
  reservation: "reservation",
  session: "session",
  allocation: "allocation",
  addon: "addon",
  merchandize: "merchandize",
  membership: "membership",
  donation: "donation"
};

export const NOTE_STATUSES = [
  { label: 'New', value: 'create' },
  { label: 'In Progress', value: 'inprogress' },
  { label: 'Done', value: 'done' },
  { label: 'Reopen', value: 'reopen' },
];

export const BUDGET_TARGET_TYPE = {
  department: 'department',
  membership: 'membership',
  campaign: 'campaign',
  order_program: 'order_program'
};

export const MERGE_FIELDS = [
  "username",
  "type",
  "title",
  "nickname",
  "first Name",
  "middle Name",
  "last Name",
  "email",
  "secondary_email",
  "gender",
  "birthday",
  "cell Phone",
  "home Phone",
  "work Phone",
  "staff",
  "staff Type",
  "company Name",
  "company Since",
  "position",
  "grade",
];

export const SCHOLARSHIP_TYPES = [
  { value: 'general', label: 'General' },
  { value: 'membership', label: 'Membership' },
  { value: 'joiners_fee', label: 'Joiners Fee' },
  { value: 'programs', label: 'Programs' },
  { value: 'day_camp', label: 'Day Camp' },
  { value: 'childcare', label: 'Childcare' },
  { value: 'residence', label: 'Residence' }
];

export const GetLabelFromListSimple = (list, value) => {
  let lable = '';
  let item = list.find(item => item.value === value);
  if(item){
    lable = item.label;
  }
  return lable;
};

export const RANGE_BIRTHDAY = (new Date().getFullYear() - 100) + ':' + (new Date().getFullYear() + 5)

export const setAssessmentSelected = (assessment) => {
  localStorage.setItem("assessmentId", assessment);
}

export const setTreeId = (treeId) => {
  localStorage.setItem("treeId", treeId);
}

export const setTreeOptId = (treeOptId) => {
  localStorage.setItem("treeOptId", treeOptId);
}

export const getOrganizations = () => {
  return localStorage.getItem("organizations");
}

export const getOrganization = () => {
  return localStorage.getItem("organization");
}


export const setOrgName = (orgName) => {
  localStorage.setItem("orgName", orgName);
}

export const getOrgName = () => {
  return localStorage.getItem("orgName");
}

export const setOrgId = (orgId) => {
  localStorage.setItem("orgId", orgId);
}

export const getOrgId = () => {
  return parseInt(localStorage.getItem("orgId"));
}

export const setSelectedDomain = (setSelectedDomain) => {
  localStorage.setItem("selectedDomain", setSelectedDomain);
}

export const getSelectedDomain = () => {
  return localStorage.getItem("selectedDomain");
}

export const setCurrentPaths = (currentPaths) => {
  localStorage.setItem("currentPaths", currentPaths);
}

export const getCurrentPaths = () => {
  return JSON.parse(localStorage.getItem("currentPaths"));
}

export const setTreeData = (treeData) => {
  localStorage.setItem("treeData", treeData);
}

export const getTreeData = () => {
  return JSON.parse(localStorage.getItem("treeData"));
}

export const setGameId = (gameId) => {
  localStorage.setItem("gameId", gameId);
}

export const getGameId = () => {
  return localStorage.getItem("gameId");
}

export const shallowEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
      return false;
  }

  for (let key of keys1) {
    let ob1 = (object1[key] !== null && key === "text") || (object1[key] !== null && key === "question") ? object1[key].trim() : object1[key] === null ? "": object1[key]
    let ob2 = (object2[key] !== null && key === "text") || (object2[key] !== null && key === "question") ? object2[key].trim() : object2[key] === null ? "": object2[key]

      if (ob1 !== ob2 && JSON.stringify(ob1) !== JSON.stringify(ob2)) {
        return false;
      }
  }

  return true;
}

export const STATUS_GAME_NEW = 0;
export const STATUS_GAME_PUBLISHED = 1;
export const STATUS_GSTATUS_GAME_PUBLISHED_PAUSEAME_CLOSED = 12;
export const STATUS_GAME_PUBLISHED_RUNNING = 11;
export const STATUS_GAME_PUBLISHED_OVER = 13;
export const STATUS_GAME_CLOSED = -1;

export const ADMIN_ROLE = [
  {value: 'SUPER ADMIN', label: 1},
  {value: 'ADMIN', label: 2},
  {value: 'COORDINATOR', label: 3},
  {value: 'ASSOCIATE', label: 4}
]

export const TASK_TYPES = [
  {value: 'Location', label: 1},
  {value: 'Location Custom', label: 14},
  {value: 'Location Pin On Map', label: 20},
  {value: 'Single Choice', label: 2},
  {value: 'Multiple Choice', label: 11},
  {value: 'Signature', label: 21},
  {value: 'QR Code', label: 3},
  {value: 'Upload Image', label: 4},
  {value: 'Free Answer', label: 5},
  {value: 'Fixed Answer', label: 6},
  {value: 'Filling Space', label: 7},
  {value: 'Matching List', label: 9},
  {value: 'Find IBeacon', label: 10},
  {value: 'Push Button', label: 12},
  {value: 'Physical Activity', label: 15},
  {value: 'Record Audio', label: 16},
  {value: 'Record Video', label: 17},
  {value: 'Hierarchy', label: 22},
  {value: 'Order List', label: 8},
]

export const TASK_TYPE = [
  {value: 'Find Location', label: 1, icon: 'location-task'},
  {value: 'Pin Location on Map', label: 20, icon: 'location-pin-task'},
  {value: 'Free Form Written Answer', label: 5, icon: 'free-task'},
  {value: 'Fixed Form Written Answer', label: 6, icon: 'fixed-task'},
  {value: 'Scan QR Code', label: 3, icon: 'qr-task'},
  {value: 'Single Choice Answer', label: 2, icon: 'multi-choice-task'},
  {value: 'Multiple Choice Answer', label: 11, icon: 'multi-choice-task'},
  {value: 'Take a Photo', label: 4, icon: 'upload-photo-task'},
  {value: 'Fill-in Blanks', label: 7, icon: 'filling-task'},
  {value: 'Order Items', label: 8, icon: 'ordering-task'},
  {value: 'Match Items', label: 9, icon: 'matching-task'},
  {value: 'Find Beacon', label: 10, icon: 'beacon-task'},
  {value: 'Push Button', label: 12, icon: 'push-button-task'},
  {value: 'Physical Actitivies', label: 15, icon: 'physical-activity-task'},
  {value: 'Record Audio', label: 16, icon: 'record-audio-task'},
  {value: 'Record Video', label: 17, icon: 'record-video-task'},
  {value: 'Hierarchy', label: 22, icon: 'hierarchy-task'},
]

export const HINT_TYPES = [
  {value: 'Text', label: 1},
  {value: 'Image', label: 2},
  {value: 'Audio', label: 3},
  {value: 'Video', label: 4},
  {value: 'Image Sharing Service', label: 5},
  {value: 'Audio Sharing Service', label: 6},
  {value: 'Video Sharing Service', label: 7},
  {value: 'Video External Service', label: 8},
  {value: 'Custom', label: 9},
  {value: 'Url', label: 10},
  {value: 'VR Image', label: 21},
]

export const HINT_TYPE = [
  {value: 'Text Hint', label: 1, icon: 'text'},
  {value: 'Web Hint', label: 10, icon: 'url'},
  {value: 'Image Hint', label: 2, icon: 'image'},
  {value: 'Audio Hint', label: 3, icon: 'speaker'},
  {value: 'Youtube Video Hint', label: 7, icon: 'youtube'},
  {value: 'Video Hint', label: 4, icon: 'vimeo'},
]

export const GAME_PASS_STAGE = [
  {value: 'Joined', label: 1},
  {value: 'Playing', label: 2},
  {value: 'Paused', label: 3},
  {value: 'Stop', label: 4},
  {value: 'Completed', label: 5},
  {value: 'Quited', label: 6},
  {value: 'Finished', label: 7},
  {value: 'Reset', label: -1},
  {value: 'Kicked', label: -2},
]