export const getAssessment = () => { return localStorage.getItem('assessmentId')};

export const getAdminRole = () => { return localStorage.getItem('adminRole') === '1' ? 'Super Admin' :  localStorage.getItem('adminRole')  === '2' ? 'Admin' : localStorage.getItem('adminRole') === '3' ? 'Coordinator' : null};

export const getRootId = () => {
    var assessmentId = getAssessment();

    switch (assessmentId){
        case 12477:
            return 1212;
        case 12392:
            return 1;
        default:
            return 0;

    }    
}

export const setTreeId = (treeId) => {
    localStorage.setItem("treeId", treeId);
}

export const getTreeId = () => {
    return parseInt(localStorage.getItem("treeId"));
}

export const setTreeOptId = (optId) => {
    localStorage.setItem("optId", optId);
}

export const getTreeOptId = () => {
    return parseInt(localStorage.getItem("optId"));
}

export const getOrgId = () => {
    return localStorage.getItem("orgId");
}


