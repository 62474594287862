import React, { Component } from 'react';
// import ThroughProvider from 'react-through/lib/ThroughProvider';
import classNames from 'classnames';
import { AppTopbar } from './core/layout/AppTopbar';
import { AppFooter } from './core/layout/AppFooter';
import { AppMenu } from './core/layout/AppMenu';
import { AppBreadcrumb } from "./core/layout/AppBreadcrumb";
import { Route, Switch, Redirect } from 'react-router-dom';
import DomHandler from 'primereact/components/utils/DomHandler';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import { withRouter } from 'react-router';
import './ripple.js';
import './App.css';
import './App.scss';
import './Restaurant.scss';
import './index.css';
import '@fullcalendar/core/main.css';
import "video-react/dist/video-react.css";
import { createBrowserHistory } from "history";
import AccessDenied from './errors/access-denied/AccessDenied';
import NotFound from './errors/not-found/NotFound';
import ErrorPage from './errors/error-page/ErrorPage';
import { logOut } from './core/security/auth.jsx';
import {getOrganization, getOrganizations, setSelectedDomain} from './constants';

import { Dashboard2 } from './dashboard/Dashboard2';
import { ROLES_CONFIG } from './roles';
import 'react-tippy/dist/tippy.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import { showNotification, showUserNotification, notificationEvent } from './core/service/NotificationService';
import { WS_BASE_URL, WS_FALLBACK_BASE_URL } from './constants';
import PermissionRoute from './core/security/PermissionRoute';
import AuthRoute from './core/security/AuthRoute';
import * as webEventService from './core/service/WebEventService';
import { Dropdown } from 'primereact/dropdown';
import LoadingOverlay from './core/components/LoadingOverlay';
import lazyLoad from './core/components/lazyLoad';
import {setAssessmentSelected, getAssessmentSelected, setOrgId, getOrgId, setOrgName} from './constants';
import { AppContext } from './context/AppContext';
import { getAdminRole } from './precision/Contants';
import { updateOrg } from './user/UserProfileService';


const ReportChart = lazyLoad(() => import('./generic/ReportChart'));
const ReportProfile = lazyLoad(() => import('./generic/ReportProfile'));
const ReportDownload = lazyLoad(() => import ('./generic/ReportDownload'));
const DashBoard = lazyLoad(() => import ('./generic/DashBoard'));
const OrganizationManagement = lazyLoad(() => import('./generic/OrganizationManagement'));
const MemberCoordinator = lazyLoad(() => import('./generic/MemberCoordinator'));
const Supervisor = lazyLoad(() => import('./generic/Supervisor'));
const Institution = lazyLoad(() => import('./generic/Institution'));
const OrganizationStructure = lazyLoad(() => import('./generic/OrganizationStructure'));
const MediaAsset = lazyLoad(() => import('./generic/MediaAsset'));
const TreeManagement = lazyLoad(() => import('./generic/TreeManagement'));
const OrganizationRole = lazyLoad(() => import('./generic/OrganizationRole'));


const HRDemo = lazyLoad(() => import('./hr/HRDemo'));
const HRModule = lazyLoad(() => import('./hr/HRModule'));

const SnsModule = lazyLoad(() => import('./sns/SnsModule'));
const UserProfileView = lazyLoad(() => import ('../src/user/UserProfileView'));
const GameListView = lazyLoad(() => import('./generic/GameListView'));
const GameEditor = lazyLoad(() => import('./generic/GameEditor'));
const GenericGameEditor = lazyLoad(() => import('./generic/GenericGameEditor'));

const PrecisionModule = lazyLoad(() => import('./precision/PrecisionModule'));
// const EPATreeManagement = lazyLoad(() => import('./precision/EPATreeManagement'));

class App extends Component {
    static contextType = AppContext;
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedApp: getOrganization() ? JSON.parse(getOrganization()).abb : 'Personal',
            appMenu: this.createMenu(getOrganization() ? JSON.parse(getOrganization()).abb : 'Personal'),
            appLogo: '/assets/fromlabs/fl_small_logo.png',
            activeTopbarItem: null,
            layoutMode: 'static',
            mobileMenuActive: null,
            topbarMenuActive: null,
            currentRoute: null,
            menuActive: false,
            darkMenu: true,
            wsNotifications: [],
            userNotifications: [],
            refreshNotification : false
        };

        this.notificationCount = 0;

        this._history = createBrowserHistory();
        this.unlisten = this._history.listen((location, action) => {
        });

        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMobileMenuButtonClick = this.onTopbarMobileMenuButtonClick.bind(this);
        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onSidebarMouseEnter = this.onSidebarMouseEnter.bind(this);
        this.onSidebarMouseLeave = this.onSidebarMouseLeave.bind(this);
        this.onToggleMenuClick = this.onToggleMenuClick.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);

        // this.initApp(this.state.selectedApp);
    }

    componentDidMount() {
        let self = this;

        this.resetPersonalOrgId();

        // webEventService.initConnection();
        // webEventService.websocketEvent.on('message', (data) => showNotification('notify', 'Notify Message', data.message));
        // this.handleLoadNotification();
    }

    componentWillMount() {
        notificationEvent.on('USER_NOTIFICATION', this.onUserNotificationReceived);
    }

    onUserNotificationReceived = (message) => {
        // this.Dashboard2.current.getTasks();
        showNotification('info', 'Info Message', 'You have been assigned a new task !');
    }

    handleLogout = (e, redirectTo="/login") => {
        e.preventDefault();
        logOut();
        this._history.push(redirectTo)
    }

    onWrapperClick(event) {
        if (!this.menuClick && !this.menuButtonClick && this.state.mobileMenuActive) {
            this.setState({mobileMenuActive: false});
        }

        if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if(!this.menuClick) {
            if(this.isHorizontal() || this.isOverlay()) {
                this.setState({
                    menuActive: false
                })
            }
        }

        this.menuClick = false;
        this.menuButtonClick = false;
        this.topbarMenuClick = false;
        this.topbarMenuButtonClick = false;
    }

    onTopbarItemClick(event) {
        this.topbarMenuClick = true;
        if (!event.toSearch && this.state.activeTopbarItem === event.item)
            this.setState({activeTopbarItem: null});
        else
            this.setState({activeTopbarItem: event.item});

        event.originalEvent.preventDefault();

    }

    onMenuButtonClick(event) {
        this.menuButtonClick = true;

        if (this.isMobile()) {
            this.setState({mobileMenuActive: !this.state.mobileMenuActive});
        }

        event.preventDefault();
    }

    onTopbarMobileMenuButtonClick(event) {
        this.topbarMenuButtonClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        event.preventDefault();
    }

    onToggleMenuClick(event) {
        this.setState({layoutMode: (this.state.layoutMode !== 'static') ? 'static' : 'overlay'})
    }

    handleChangeOverlayToggleMenu = () => {
        this.setState({
            layoutMode: 'overlay'
        })
    }

    handleChangeStaticToggleMenu = () => {
        this.setState({
            layoutMode: 'static'
        })
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onSidebarMouseEnter(event) {
        if (this.sidebarTimeout) clearTimeout(this.sidebarTimeout);
        if (this.sidebar) DomHandler.addClass(this.sidebar, 'layout-sidebar-active');
    }

    onSidebarMouseLeave(event) {
        this.sidebarTimeout = setTimeout(() => {
            if (this.sidebar) DomHandler.removeClass(this.sidebar, 'layout-sidebar-active');
        }, 250);
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive,
            isOrgChanged: false
        });
    }

    onMenuItemClick(event) {
        if(!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false,
                isOrgChanged: false
            })
        }
    }

    resetPersonalOrgId = () => {
        let orgId = 0;
        if (getOrganization()) {
            orgId = JSON.parse(getOrganization()).id
        } else if(this.context.state.selectedApp === 'Personal') {
            orgId = 0;
        } 
        setOrgId(orgId);
        updateOrg().then(data => {
            if (data && data.status && data.status.code === 1) {
                setOrgId(orgId);
            } 
        })
        
    }

    createMenu(app){
        setSelectedDomain(app);
        let adminRole = getAdminRole();
        switch(app){
            case 'Personal':
                return [
                    // {label: 'Dash Board', icon:'home', to: `/dash-board`},
                    {label: 'Profile', icon:'person', to: `/profile`},
                    {label: 'Assessment flow', icon: 'games', to: `/games`},
                    
                    {label: 'Asset Management', icon: 'assessment', 
                        items: [
                            {label: 'Tree Management', icon: 'format_list_bulleted', to: `/asset-management/tree-management`},
                            {label: 'Media', icon: 'perm_media', to: `/asset-management/media`}
                        ]
                    },
                    {label: 'Reports', icon: 'report', 
                        items: [
                            {label: 'Statistic', icon: 'insert_chart', to: `/reports/statistic`},
                            {label: 'Profile', icon: 'trending_up', to: `/reports/profile`},
                            {label: 'Download', icon: 'file_download', to: `/reports/download`}
                        ]
                    }
                ]

            default: return [
                // {label: 'Dash Board', icon:'home', to: `/dash-board`},
                {label: 'Profile', icon:'person', to: `/profile`},
                {label: 'Assessment flow', icon: 'games', to: `/games`},
                
                (adminRole === 'Super Admin' || adminRole === "Admin") && {label: 'Organization Mgt', icon: 'group_work',
                    items: [
                        {label: 'Org. Details', icon: 'perm_identity', to: `/member-management/admin`},
                        {label: 'Users', icon: 'people', to: `/member-management/users`},
                        {label: 'Requests', icon: 'person_add', to: `/member-management/permission-requests`},
                        {label: 'Org. Structure', icon: 'map', to: `/member-management/organization-structure`},
                        {label: 'Roles', icon: 'verified_user', to: `/member-management/roles`}
                    ]
                }, 
                {label: 'Asset Management', icon: 'assessment', 
                    items: [
                        {label: 'Tree Management', icon: 'format_list_bulleted', to: `/asset-management/tree-management`},
                        {label: 'Media', icon: 'perm_media', to: `/asset-management/media`}
                    ]
                },
                {label: 'Reports', icon: 'report', 
                    items: [
                        {label: 'Statistic', icon: 'insert_chart', to: `/reports/statistic`},
                        {label: 'Profile', icon: 'trending_up', to: `/reports/profile`},
                        {label: 'Download', icon: 'file_download', to: `/reports/download`}
                    ]
                }
            ].filter(Boolean);
        }
    }

    changeTheme(theme) {
        this.changeStyleSheetUrl('theme-css', theme, 'theme');
    }

    changeLayout(theme) {
        this.changeStyleSheetUrl('layout-css', theme, 'layout');
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');
        element.setAttribute('href', newURL);
    }

    isMobile() {
        return window.innerWidth <= 1024;
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    componentWillUnmount() {
        this.unlisten();
        // notificationEvent.remove('USER_NOTIFICATION', this.onUserNotificationReceived);
    }

    showNotification = (type, message) => {
        this.notification.show({severity: type, summary: type==='success'?'Success Message':'Error Message', detail: message});
    }

    initApp(app) {
        this.context.actions.handleSelectedApp(app);
        switch(app){
            default:
                this.setState({
                    selectedApp: app,
                    appLogo: '/assets/fromlabs/fl_small_logo.png',
                    appMenu: this.createMenu(app),
                    menuActive: false,
                    isOrgChanged: true
                });
                // this._history.push(`/dash-board`);
                this.props.history.push(`/profile`);
                break;
        }
    }

    handleChangeApp = e => {
        let orgId = 0;
        let orgName = "Personal";
        if (getOrganization()) {
            orgId = JSON.parse(getOrganization()).id;
            orgName= JSON.parse(getOrganization()).name;
        } else if(e.value === "Personal") {
            // setOrgId(0);
            // setOrgName('Personal');
        } else {
            var organizations = JSON.parse(getOrganizations());

            for(var i = 0; i < organizations.length; i++) {
                if(organizations[i].abb === e.value){
                    orgId = organizations[i].id;
                    orgName = organizations[i].name;
                    break;
                }
            }
        }

        setOrgId(orgId);
        setOrgName(orgName)
        updateOrg().then(data => {
            if (data && data.status && data.status.code === 1) {
                setOrgId(orgId);
                setOrgName(orgName)
            }
        })

        if(e.value !== null && e.value !== this.state.selectedApp){
            this.initApp(e.value);
        }
    }

    render() {
        let wrapperClass = classNames('layout-wrapper', {
                                        'layout-wrapper-static': this.state.layoutMode === 'static',
                                        'layout-wrapper-active': this.state.mobileMenuActive,
                                        'layout-menu-horizontal': this.state.layoutMode === 'horizontal'
                                    });
        let sidebarClassName = classNames("layout-sidebar",{'layout-sidebar-dark': this.state.darkMenu});

        const adminRole = getAdminRole();

        const getAppList = () => {
            var organization = JSON.parse(getOrganization());
            if (organization) {                
                var item = {};
                item.label = organization.name;
                item.value = organization.abb;
                return [item];
            }
            
            var organizations = JSON.parse(getOrganizations());

            if(organizations === null || organizations === undefined || organizations === 'null' || organizations === 'undefined'){
                localStorage.clear();
                window.location.assign("/");
            }

            var appList = [
                {
                    label: "Personal",
                    value: "Personal",
                    orgId: 0
                }
            ];
    
            for(var i = 0; i < organizations.length; i++){
                var item = {};
                item.label = organizations[i].name;
                item.value = organizations[i].abb;
                appList.push(item);
            }

            return appList;
        }

        const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

        return (
            <AppContext.Consumer>
                {(context) => (

                    <div className={wrapperClass} onClick={this.onWrapperClick}>
                        <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}
                            onMouseEnter={this.onSidebarMouseEnter} onMouseLeave={this.onSidebarMouseLeave}>
        
                            <div className="sidebar-logo">
                                <a>
                                    <img className="app-logo" alt="logo" src={this.state.appLogo} />
                                    <span className="app-name" style={{color: 'black'}}> {this.state.selectedApp}{getAppList().length > 1?<Dropdown className="org-list" style={{width:'42px'}} value={this.state.selectedApp} options={getAppList()} onChange={this.handleChangeApp}></Dropdown>:''}</span>
                                </a>
                                <a className="sidebar-anchor" style={{backgroundColor: 'black'}} title="Toggle Menu" onClick={this.onToggleMenuClick}> </a>
                            </div>
        
                            <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{height:'100%'}}>
                                <div className="layout-menu-container" >
                                    <AppMenu model={this.state.appMenu} onRootMenuItemClick={this.onRootMenuItemClick} layoutMode={this.state.layoutMode}
                                            active={this.state.menuActive} onMenuItemClick={this.onMenuItemClick} isOrgChanged={this.state.isOrgChanged}/>
                                </div>
                            </ScrollPanel>
                        </div>
                        <div className="layout-main">
                            
                            <link rel="stylesheet" href="https://unpkg.com/primeicons@2.0.0/primeicons.css"/>
                            <AppTopbar profileName={localStorage.getItem('fullname')} layoutMode={this.state.layoutMode} activeTopbarItem={this.state.activeTopbarItem} onTopbarItemClick={(e) => this.onTopbarItemClick(e)}
                                    onMenuButtonClick={this.onMenuButtonClick} onTopbarMobileMenuButtonClick={this.onTopbarMobileMenuButtonClick}
                                    topbarMenuActive={this.state.topbarMenuActive} onLogout={this.handleLogout} wsNotifications={this.state.wsNotifications} userNotifications={this.state.userNotifications}
                                    reloadUserNotification={this.reloadUserNotification}/>
        
                            {/* <AppBreadCrumbWithRouter onLogout={this.handleLogout} />                     */}
                            <div className="layout-content" id="layout-content-container">
                                <LoadingOverlay />
                                <Switch>
                                    {/* <PermissionRoute path="/" exact component={Dashboard2} roles={[ROLES_CONFIG.DASHBOARD_R]}/> */}
                                    <AuthRoute path="/500" component={ErrorPage} />
                                    <AuthRoute path="/404" component={NotFound} />
                                    <AuthRoute path="/401" component={AccessDenied} />
                                    
                                    {/* <Route path="/hr/demo" component={HRDemo} />
                                    <Route path="/hr" component={HRModule} /> */}
                                    
                                    <Route path="/reports/download" render={(props) => <ReportDownload {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} />
                                    <Route path="/reports/statistic" render={(props) => <ReportChart {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} />
                                    <Route path="/reports/profile" render={(props) => <ReportProfile {...props} setToggleMenu={this.handleChangeOverlayToggleMenu}/>} />
                                    <Route path="/asset-management/media" render={(props) => <MediaAsset {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} />
                                    <Route path="/asset-management/tree-management" render={(props) => <TreeManagement {...props} setToggleMenu={this.handleChangeOverlayToggleMenu}/>} />
                                    {/* <Route path="/member-management/organization-structure" render={(props) => <Institution {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} /> */}
                                    
                                    {(adminRole === 'Super Admin' || adminRole === "Admin") && <Route path="/member-management/roles" render={(props) => <OrganizationRole {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} />}
                                    {(adminRole === 'Super Admin' || adminRole === "Admin") && <Route path="/member-management/permission-requests" render={(props) => <Supervisor {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} />}
                                    {(adminRole === 'Super Admin' || adminRole === "Admin") && <Route path="/member-management/organization-structure" render={(props) => <OrganizationStructure {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} />}
                                    {(adminRole === 'Super Admin' || adminRole === "Admin") && <Route path="/member-management/admin"  render={(props) => <OrganizationManagement {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} />}
                                    {(adminRole === 'Super Admin' || adminRole === "Admin") && <Route path="/member-management/users"  render={(props) => <MemberCoordinator {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} />}

                                    <Route path="/games/editor/:id" render={(props) => <GameEditor {...props} setToggleMenu={this.handleChangeOverlayToggleMenu}/>} />
                                    <Route path="/games" render={(props) => <GameListView {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} />
                                    <Route path="/profile" render={(props) => <UserProfileView {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} />
                                    {/* <Route path="/dash-board" render={(props) => <DashBoard {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} /> */}
                                    {/* <Route path="/" render={(props) => <DashBoard {...props} setToggleMenu={this.handleChangeStaticToggleMenu}/>} /> */}
        
                                    <Route path="/scs/supervisor" component={Supervisor} />
                                    <Route path="/scs/member-coordinator" component={MemberCoordinator} />
                                    {/* <Route path="/scs/epa-management" component={EPATreeManagement} />  */}
                                    <Route path="/scs/reports" component={ReportChart} /> 
                                    <Route path="/scs" component={PrecisionModule} /> 
                                    
        
                                    <Redirect from='*' to='/404' />
                                </Switch>
                            </div>
        
                            <AppFooter />
        
                            {this.state.mobileMenuActive && <div className="layout-main-mask"></div>}
                        </div>
                    </div>
                )}
            </AppContext.Consumer>
        );
    }
}

export default App;
