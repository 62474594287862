import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';

export class Dashboard2 extends Component {

    constructor() {
        super();
        this.state = {
            priceTransaction: {
                priceDay: 0,
                priceMonth: 0,
                priceYear: 0,
                pricePending: 0
            },
            countNewOrders: 0,
            departmentTracking: {
                totalReg: 0,
                totalWaitingList: 0,
                totalCheckout: 0.0
            },
            myTasks : []
        }
    }

    componentDidMount(){
        this.getPriceTransactionStatistic();
        this.getNewOrders();
        this.loadDeparments();
        this.trackingDepartment();
    }

    getPriceTransactionStatistic = () => {
        
    }

    getNewOrders = () => {

    }

    loadDeparments = () => {

    }

    trackingDepartment = () => {
        
    }

    onChageDepartment = (e) => {

    }

    render() {

        return(
            <div className="p-grid dashboard">
                
                <div className="p-col-12 p-md-3">
                    <div className="overview-box overview-box-4">
                        <div className="overview-box-header"></div>
                        <div className="overview-box-content">
                            <div className="overview-box-icon"><i className="material-icons">attach_money</i></div>
                            <div className="overview-box-title">Daily</div>
                            <div className="overview-box-count">{this.state.priceTransaction.priceDay}</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-3">
                    <div className="overview-box overview-box-2">
                        <div className="overview-box-header"></div>
                        <div className="overview-box-content">
                            <div className="overview-box-icon"><i className="material-icons">attach_money</i></div>
                            <div className="overview-box-title">Monthly</div>
                            <div className="overview-box-count">{this.state.priceTransaction.priceMonth}</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-3">
                    <div className="overview-box overview-box-3">
                        <div className="overview-box-header"></div>
                        <div className="overview-box-content">
                            <div className="overview-box-icon"><i className="material-icons">attach_money</i></div>
                            <div className="overview-box-title">Yearly</div>
                            <div className="overview-box-count">{this.state.priceTransaction.priceYear}</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-3">
                    <div className="overview-box overview-box-1">
                        <div className="overview-box-header"></div>
                        <div className="overview-box-content">
                            <div className="overview-box-icon"><i className="material-icons">attach_money</i></div>
                            <div className="overview-box-title">All Pending</div>
                            <div className="overview-box-count">{this.state.priceTransaction.pricePending}</div>
                        </div>
                    </div>
                </div>
                
                <div className="p-col-12 p-md-4">
                    <div className="overview-box overview-box-1">
                        <div className="overview-box-header"></div>
                        <div className="overview-box-content">
                            <div className="overview-box-icon"><i className="material-icons">inbox</i></div>
                            <div className="overview-box-title">
                                <Link to={`/orders?state=new_order`} target="_blank" style={{color: "#ffffff"}}>
                                    New Order                                
                                </Link>
                            </div>
                            <div className="overview-box-count">{this.state.countNewOrders}</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-4">
                    <div className="overview-box overview-box-2">
                        <div className="overview-box-header"></div>
                        <div className="overview-box-content">
                            <div className="overview-box-icon"><i className="material-icons">supervisor_account</i></div>
                            <div className="overview-box-title">Active Membership</div>
                            <div className="overview-box-count">152</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-4">
                    <div className="overview-box overview-box-3">
                        <div className="overview-box-header"></div>
                        <div className="overview-box-content">
                            <div className="overview-box-icon"><i className="material-icons">supervisor_account</i></div>
                            <div className="overview-box-title">Expired Membership</div>
                            <div className="overview-box-count">152</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>Department Tracking In Last 24hr</h1>
                                
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12 p-md-4">
                                                <Dropdown value={this.state.departmentTracking.departmentId} options={this.state.departments} onChange={(e) => this.onChageDepartment(e)} placeholder="All department" />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="overview-box overview-box-1">
                                            <div className="overview-box-header"></div>
                                            <div className="overview-box-content">
                                                <div className="overview-box-icon"><i className="material-icons">supervisor_account</i></div>
                                                <div className="overview-box-title">Total Reg Number</div>
                                                <div className="overview-box-count">{this.state.departmentTracking.totalReg}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="overview-box overview-box-2">
                                            <div className="overview-box-header"></div>
                                            <div className="overview-box-content">
                                                <div className="overview-box-icon"><i className="material-icons">supervisor_account</i></div>
                                                <div className="overview-box-title">Waiting List Number</div>
                                                <div className="overview-box-count">{this.state.departmentTracking.totalWaitingList}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="overview-box overview-box-3">
                                            <div className="overview-box-header"></div>
                                            <div className="overview-box-content">
                                                <div className="overview-box-icon"><i className="material-icons">attach_money</i></div>
                                                <div className="overview-box-title">Total revenue</div>
                                                <div className="overview-box-count">{Number(this.state.departmentTracking.totalCheckout).toFixed(2)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}