import axios from "axios";
import querystring from 'querystring';
import { CLIENT_ID, CLIENT_CREDENTIALS, API_BASE_URL } from '../constants';
import { saveLoginInfo, saveLoginOrgInfo, getRefreshToken } from '../core/security/auth.jsx';

// let API_BASE_URL = LOGIN_BASE_URL;

function getSubDomain() {
    let parts = window.location.host.split(".");
    let subdomain = "ymca";
    // If we get more than 3 parts, then we have a subdomain
    // INFO: This could be 4, if you have a co.uk TLD or something like that.
    if (parts.length >= 3) {
        subdomain = parts[0];
    }
    return subdomain;
}

function getTenantAlias(subdomain) {
    return subdomain.split("-")[0];
}

// export function login(username, password) {
//     return axios.post(`${API_BASE_URL}/login`, 
//         querystring.stringify({
//             username: username,
//             password: password,
//             grant_type: 'password',
//             client_id: CLIENT_ID
//         }), {
//             headers: { 
//                 'Content-Type': 'application/x-www-form-urlencoded',
//                 'Tenant': getTenantAlias(getSubDomain())
//             },
//             auth: {
//                 username: CLIENT_ID,
//                 password: CLIENT_CREDENTIALS
//             },
//         })
//         .then(res => res.data)
//         .then(data => {
//             if(!data.error){
//                 saveLoginInfo(data);
//             }
//             return data;
//         });
// }

export function login(username, password){
    const headers = {
        'email': username,
        'pass': password,
        // 'application': 'UHZ',
        'WebToken':'nonNull'
    }
    
    return axios.post(`${API_BASE_URL}/app/rest/auth`, null, {
        headers: headers
    })
    .then(res => res.data)
    .then(data => {
        if(!data.error){
            if (data.loggedUser && data.loggedUser.type === 2) {
                saveLoginOrgInfo(data)
            } else {
                saveLoginInfo(data);
            }
        }
        return data;
    })
}

export function refreshToken() {
    return axios.post(`${API_BASE_URL}/login`, 
        querystring.stringify({
            refresh_token: getRefreshToken(),
            grant_type: 'refresh_token',
            client_id: CLIENT_ID
        }), {
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Tenant': getTenantAlias(getSubDomain())
            },
            auth: {
                username: CLIENT_ID,
                password: CLIENT_CREDENTIALS
            },
    })
    .then(res => res.data)
    .then(data => {
        if (data.loggedUser && data.loggedUser.type === 2) {
            saveLoginOrgInfo(data)
        } else {
            saveLoginInfo(data);
        }
        return data;
    });
}