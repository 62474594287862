import React, {Component} from 'react';
import {ProgressSpinner} from 'primereact/progressspinner';
import { loadingEvent } from '../service/LoadingService';
 
export default class LoadingOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    }
  }

  componentWillMount() {
    loadingEvent.on('LOADING', this.showLoading)
  }

  showLoading = (show) => { 
    this.setState({show: show})
  }

  render() {
    const overlayStyle = {...styles.overlay, display: this.state.show ? 'block': 'none'};
    return (
      <div style={overlayStyle}>
        <div style={styles.spinnerBlock}>
          <ProgressSpinner strokeWidth="4"/>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    loadingEvent.removeListener('LOADING', this.showLoading)
  }
}


const styles = {
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2,
    textAlign: 'center'
  },
  spinnerBlock: {
    position: 'relative',
    top: '50%',
    zIndex: 3,
    textAlign: 'center'
  }
}
