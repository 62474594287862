import React, {Component} from 'react';
import '../login/Login.css';
import {Password} from 'primereact/password';
import { Button } from 'primereact/button';
// import { UserServices } from '../constituent-management/contact/UserServices';
import ReCAPTCHA from "react-google-recaptcha";
// import { ConfigurationServices } from '../tenant-configuration/ConfigruationServices';

export default class ResetPasswordLink extends Component {
    constructor() {
        super();
        this.state = {
            newPassword: '',
            confirmNewPassword:'',
            token: ''
        }

        // this.userServices = new UserServices();
        // this.configurationServices = new ConfigurationServices();
    }

    handleAccountEnter = (e) => {
        if (e.key === 'Enter'){
            
        }
    }

    handleChangePassword = () => {
        if(this.state.captchaFlag === false){
            alert("Captcha is required !");
        }else {
            if(this.state.newPassword !== this.state.confirmNewPassword){
                alert("Confirmed password is not match");
            }else{
            // this.userServices.saveChangePassword(this.state.newPassword, this.state.token)
            // .then(data => {
            //     if(data == "NOT_FOUND"){
            //         alert("Can not change new password");
            //     }else {
            //         alert("New password has been changed");
            //     }
            // })
        }
    }
    }

    getMailTemplate = () => {
        // this.configurationServices.getMailTemplate()
        // .then(res => {
        //     this.setState({
        //         mailTemplateResetPassword: res.systemConfig.mailTemplateResetPassword ? res.systemConfig.mailTemplateResetPassword : 0
        //     });
        // })
    }

    getParams = () => {
        let query = new URLSearchParams(window.location.href);
        let token = query.get('t');
        this.setState({
            token: token
        });
    }

    componentDidMount() {
        this.getParams();
    }

    render() {
        return (
            <div className="login-body">
                 <div className="login-panel p-fluid">
                    <div className="login-panel-header">
                        <img className="logo" src="assets/fromlabs/logo.png" alt="Logo"/>
                    </div>
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div style={{margin:"auto", textAlign:"center"}}>
                                <h1>Change New Password</h1>
                            </div>
                            <div className="p-col-12">
                                <label>New password</label>
                                <Password value={this.state.newPassword} onChange={(e) => this.setState({newPassword: e.target.value})} onKeyPress={this.handleAccountEnter}/>  
                            </div>
                            <div className="p-col-12">
                                <label>Confirm New password</label>
                                <Password value={this.state.confirmNewPassword} onChange={(e) => this.setState({confirmNewPassword: e.target.value})} onKeyPress={this.handleAccountEnter}/>   
                            </div>
                            <div className="p-margin-left-15">
                            <ReCAPTCHA
                                sitekey="6LeTGcoUAAAAAI4N9_s7t_WGyOyocpOcT-OzSy0B"
                                onChange={(e) => this.setState({captchaFlag : true})}
                            />
                            </div>
                            <div className="p-col-12">
                                <Button label="Submit" onClick={this.handleChangePassword}/>
                            </div>
                            <div className="p-col-12 ">
                                <Button label="Login" onClick={() => {window.open('/login'); window.close()}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}