import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../core/security/auth.jsx';
import { login as requestLogin } from "./AuthServices.jsx";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { withNamespaces, Trans } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { languages } from '../core/internationalization/language';
import './Login.scss';

import { showNotification } from '../core/service/NotificationService.js';
import {AppContext} from '../context/AppContext';

class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: '',
            loading: false,
            language: {name: 'English', value: 'en'}
        };
    }

    static contextType = AppContext;

    handlePasswordEnter = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab'){
            this.setState({loading: true},() => this.login())
        }
    }

    changeLanguage = (e) => {
        this.setState({language: e.value});
        const { i18n } = this.props;
        i18n.changeLanguage(e.value.value);
    }

    login = () => {
        requestLogin(this.state.username, this.state.password)
            .then(res => {
                // this.context.actions.saveOrganizations(res.organizations);
                if(!res.error) this.setState({ redirectToReferrer: true, loading: false });
                else if (res) {
                    this.setState({isError: res.status.message})
                }
            })
            .catch(err => {
                this.setState({isError: 'Username or password is incorrect'})
            });
    }

    render() {
        const { t } = this.props;
        const trans = (path) => t(path, { framework: "react-i18next" });
        const authenticated = isAuthenticated();

        let { from } = { from: { pathname: "/games" } };
        let { redirectToReferrer } = this.state;        
        if (redirectToReferrer || authenticated){
            return <Redirect to={from}/>;
        } 

        return (
            <AppContext.Consumer>
                {(context) => (
                    <div className="login-body">
                        <div className="login-panel p-fluid">
                            <div className="login-panel-header fl-login-header">
                                {/* <img className="logo" src="assets/fromlabs/fl_small_logo.png" alt="Logo"/> */}
                                <img src="/assets/fromlabs/logo_seeknspot.png" alt="Logo"/>
                            </div>
                            <div className="login-panel-content">
                                {this.state.loading ? 
                                    <div className="p-col-12" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <ProgressSpinner style={{width: '40px', height: '40px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
                                    </div>  
                                    : null
                                }
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <Trans i18nKey='login.signin_header'>
                                            <h1>Sign-in</h1>
                                        </Trans>
                                    </div>
                                    {this.state.isError && <div className="p-col-12 p-c message-login"><Message severity="error" text={this.state.isError} /></div>}
                                    <div className="p-col-12">
                                        <Trans i18nKey='login.login_name'>
                                            <label>Username</label>
                                        </Trans>
                                        <InputText value={this.state.username} onChange={(e) => this.setState({username: e.target.value})}/>
                                            
                                    </div>
                                    <div className="p-col-12">
                                        <Trans i18nKey='login.password'>
                                            <label>Password</label>
                                        </Trans>
                                        <Password value={this.state.password} feedback={false} onChange={(e) => this.setState({password: e.target.value})} onKeyPress={this.handlePasswordEnter}/>
                                            
                                    </div>
                                    <div className="p-col-12">
                                        <Button label={ trans('login.signin')} icon="pi pi-user" onClick={() => this.login()} />
                                    </div>
                                    {/* <div className="recover-username">
                                            Recover my 'Username': <Link to="/recover-username">Recover</Link>
                                    </div>
                                    <div className="reset-password">
                                            Forget my 'Password': <Link to="/reset-password">Reset</Link>
                                    </div> */}
                                    {/* <div className="p-col-12">
                                        {trans('login.change_language')}
                                        <Dropdown 
                                            value={this.state.language} 
                                            options={languages} 
                                            onChange={this.changeLanguage} 
                                            style={{width:'150px'}} 
                                            optionLabel="name"/>
                                    </div> */}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                )}
            </AppContext.Consumer>
        );
    }
}

export default withNamespaces('message')(Login);