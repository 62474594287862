import EventEmitter from 'eventemitter3';
import * as io from 'socket.io-client';
import { WS_BASE_URL } from "../../constants";

let socket = null;

export function initConnection() {
  if (!socket) {
    const options = {
      query: `token=${localStorage.getItem('access_token')}`
    }
    socket = io(WS_BASE_URL, options);
    handleMessage();
  }
}

export const websocketEvent = new EventEmitter();

// Add events here so we could know what will be handled
const userEvents = [
  'message'
];

function handleMessage() {
  for (const userEvent of userEvents) {
    socket.on(userEvent, (data) => {
      websocketEvent.emit(userEvent, data);
    });
  }

  socket.on('connect_error', (error) => {
    console.log('connect_error', error);
  });

  socket.on('connect_timeout', (error) => {
    console.log('connect_timeout', error);
  });

  socket.on('error', (error) => {
    console.log('error', error);
  });
}
export function close() {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
}


