import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import Login from './login/Login';
import App from './App.js';
import AuthRoute from './core/security/AuthRoute.jsx';
import ThroughProvider from 'react-through/lib/ThroughProvider';
import store from './store';
import { loadPublicConfigrations } from './core/service/TenantService.js';
import ResetPassword from './reset-password/ResetPassword.js';
import RecoverUsername from './reset-password/RecoverUsername.js';
import ResetPasswordLink from './reset-password/ResetPasswordLink.js';
import {AppContext} from './context/AppContext';
import { setCurrentPaths, getOrganization } from './constants';

export class Main extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            selectedApp: getOrganization() ? JSON.parse(getOrganization()).abb : 'Personal'
        }
    }

    componentDidMount() {
        // loadPublicConfigrations();
    }

    handleSelectedApp = (selectedApp) => {
        this.setState({
            selectedApp: selectedApp
        })
    }

    render() {
        return (
            <AppContext.Provider value={{
                state: this.state,
                actions: {
                    handleSelectedApp: this.handleSelectedApp
                }
            }}>
                <ThroughProvider>
                    <Provider store={store}>
                        <Switch>
                            <Redirect exact from="/" to="/profile" />

                            <Route path="/login" component={Login}/>
                            <Route path="/reset-password" component={ResetPassword}/>
                            <Route path="/confirm_change_pass" component={ResetPasswordLink}/>
                            <Route path="/recover-username" component={RecoverUsername}/>
    
                            <AuthRoute path="/" component={App} />
                        </Switch>
                    </Provider>
                </ThroughProvider>
            </AppContext.Provider>
        )
    }
}
