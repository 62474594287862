import React, { Component } from 'react';
import {Growl} from 'primereact/growl';
import { notificationEvent } from '../service/NotificationService';

export default class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentWillMount() {
    notificationEvent.on('NOTIFY', this.showNotification)
  }

  showNotification = ({severity, summary, detail}) => { 
    this.growl.show({severity, summary, detail});
  }

  render() {
    return <Growl ref={(el) => this.growl = el} />
  }

  componentWillUnmount() {
    notificationEvent.removeListener('NOTIFY', this.showNotification)
  }
}
