export const isAuthenticated = () => localStorage.getItem('token') !== null;
// export const isAuthenticated = () => true;

// const currentUserRoles = JSON.parse(localStorage.getItem('roles'));
export const hasRole = (roles) => {
  return true;
  // let storedRoles = localStorage.getItem('roles');
  // let currentUserRoles = [];
  // if (storedRoles) {
  //   try {
  //     currentUserRoles = JSON.parse(storedRoles);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // return isAuthenticated() && currentUserRoles && roles.some(role => currentUserRoles.includes(role));
}

// const b64DecodeUnicode = str =>
//   decodeURIComponent(
//     Array.prototype.map.call(atob(str), c =>
//       '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
//     ).join(''))

// const parseJwt = token =>
//   JSON.parse(
//     b64DecodeUnicode(
//       token.split('.')[1].replace('-', '+').replace('_', '/')
//     )
//   )

// export const saveLoginInfo = ({ access_token, refresh_token }) => {
//   if(access_token && refresh_token) {
//     localStorage.setItem('access_token', access_token);
//     localStorage.setItem('refresh_token', refresh_token);
//     const userDetails = parseJwt(access_token);
//     localStorage.setItem('roles', JSON.stringify(userDetails.authorities));
//     localStorage.setItem('username', userDetails.user_name);
//     localStorage.setItem('fullname', userDetails.name);
//     localStorage.setItem('user_id', userDetails.id);
//     localStorage.setItem('client_id', userDetails.tenantId);
//     localStorage.setItem('tenant_country_code', userDetails.tenantCountryCode);
//     localStorage.setItem('tenant_state_code', userDetails.tenantStateCode);
//     localStorage.setItem('depts', JSON.stringify(userDetails.depts));
//     localStorage.setItem('personalData', {
//       'notifications': []
//     });
//   }
// }


// export const logOut = () => {
//   localStorage.removeItem('access_token');
//   localStorage.removeItem('refresh_token');
//   localStorage.removeItem('roles');
//   localStorage.removeItem('username');
//   localStorage.removeItem('fullname');
//   localStorage.removeItem('user_id');
  
//   localStorage.removeItem('client_id');
//   localStorage.removeItem('tenant_country_code');
//   localStorage.removeItem('tenant_state_code');
//   localStorage.removeItem('depts');
//   localStorage.removeItem('personalData');
// }

export const saveLoginInfo = ({token, adminRole, assessment, organizations}) => {
  if(token){
    localStorage.setItem('token', token);
    localStorage.setItem('adminRole', adminRole);
    localStorage.setItem('assessment', assessment);
    localStorage.setItem('assessmentId', 12392);
    localStorage.setItem('treeId', 1);
    localStorage.setItem('treeOptId', 1);
    localStorage.setItem('organizations', JSON.stringify(organizations));
  }
}

export const saveLoginOrgInfo = ({token, adminRole, assessment, organization}) => {
  if(token){
    localStorage.setItem('token', token);
    localStorage.setItem('adminRole', adminRole);
    localStorage.setItem('assessment', assessment);
    localStorage.setItem('assessmentId', 12392);
    localStorage.setItem('treeId', 1);
    localStorage.setItem('treeOptId', 1);
    localStorage.setItem('organization', JSON.stringify(organization));
  }
}

export const logOut = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('adminRole');
  localStorage.removeItem('assessment');
  localStorage.removeItem('assessmentId');
  localStorage.removeItem('treeId');
  localStorage.removeItem('treeOptId');
  localStorage.removeItem('treeOptId');
  localStorage.removeItem('organization');
  localStorage.removeItem('organizations');
}

export const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
}

export const loggedIn = () => {
  return localStorage.auth;
}
