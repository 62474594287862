import React, { Component } from 'react';
import {InputText} from 'primereact/components/inputtext/InputText'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from 'react-avatar';
import './AppTopbar.css';
import { SearchServices } from '../../search/SearchServices';
import { Link } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import UserNotification from '../components/UserNotification';
import { getOrgName, getOrganization } from '../../constants';
import {AppContext} from '../../context/AppContext';

export class AppTopbar extends Component {
    static contextType = AppContext;

    static defaultProps = {
        activeTopbarItem: null,
        topbarMenuActive: null,
        onMenuButtonClick: null,
        onTopbarItemClick: null,
        onTopbarMobileMenuButtonClick: null,
        layoutMode: 'overlay',
        wsNotifications: [],
        userNotifications: [],
        notificationFilter : []
    }

    static propTypes = {
        activeTopbarItem: PropTypes.string,
        topbarMenuActive: PropTypes.bool,
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        onTopbarMobileMenuButtonClick: PropTypes.func.isRequired,
        layoutMode: PropTypes.string,
        wsNotifications: PropTypes.array,
        userNotifications: PropTypes.array,
        notificationFilter: PropTypes.array
    }

    constructor() {
        super();
        this.state = {
            searchValue: '',
            campaigns: [],
            programs: [],
            courses: [],
            users: [],
            households: [],
            companies: [],
            departments: [],
            loading: false,
            showList: true,
            notificationCount : 0,
            userNotifications : []
        };
        this.searchServices = new SearchServices();
    }

    handelSearch = (e) => {
        this.onTopbarItemClick(e, 'search', true);
        this.setState({ loading: true});

        this.searchServices.search(this.state.searchValue)
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    loading: false,
                    campaigns: res.campaigns ? res.campaigns : [],
                    programs:  res.programs ? res.programs : [],
                    courses:  res.courses ? res.courses : [],
                    users:  res.users ? res.users : [],
                    households:  res.households ? res.households : [],
                    companies:  res.companies ? res.companies : [],
                    departments:  res.departments ? res.departments : [],
                })
            }else{
                
            }
        })
    }

    onChangeInputSearch = (e) => {
        this.setState({ 
            searchValue: e.target.value
        })
    }

    handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            this.handelSearch(e);
        }
    }

    onTopbarItemClick(event, item, toSearch) {
        if(this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item,
                toSearch: toSearch
            });
        }
    }

    handleDismiss = (indexFilter) => {
        
    }

    handleRead = (indexFilter) => {
        
    }

    countNotification = () => {
        let userNotifications = this.props.userNotifications;
        let count = 0;

        for(var i in userNotifications){
            if(userNotifications[i].readMode === 0){
                count += 1;
            }
        }
        this.setState({
            notificationCount : count
        })
    }

    componentDidMount(){
        this.countNotification();
    }


    componentDidUpdate(prevProps) {
        if(prevProps.userNotifications !== this.props.userNotifications){
            this.countNotification();
        }
    }

    componentWillUpdate(prevProps) {
        if(prevProps.userNotifications !== this.props.userNotifications){
            this.countNotification();
        }
    }

    render() {
        let topbarClass = classNames('topbar-menu fadeInDown',{'topbar-menu-active': this.props.topbarMenuActive})
        let horizontalIcon = (this.props.layoutMode === 'horizontal') &&
            <button className="p-link topbar-logo">
                <img alt="logo" src="assets/layout/images/logo-slim.png" />
                <span className="app-name">SCP</span>
            </button>;
        
        let userNotifications = this.props.userNotifications;
        let wsNotifications = this.props.wsNotifications;

        return (
            <AppContext.Consumer>
                {
                    (context) => (
                        <div className="layout-topbar">
                            {horizontalIcon}
                            <img alt="logo" src="/assets/fromlabs/fl_small_logo.png" className="mobile-logo"/>

                            <button className="p-link menu-btn" onClick={this.props.onMenuButtonClick}>
                                <i className="material-icons">&#xE5D2;</i>
                            </button>

                            <button className="p-link topbar-menu-btn" onClick={() => window.location.assign('/profile')}>
                                <i className="material-icons">&#xE853;</i>
                            </button>

                            <div style={{position: 'absolute', left: '55%', top: '10%', color: 'white'}}>
                                <h2 style={{textTransform: 'capitalize'}}>{getOrganization() ? JSON.parse(getOrganization()).abb : this.context.state.selectedApp === 'Personal' ? 'Personal' : getOrgName()}</h2>
                            </div>

                            <div className="layout-topbar-menu-wrapper">
                                <ul className={topbarClass}>
                                    <li  className={classNames('profile-item', {'active-topmenuitem': this.props.activeTopbarItem === 'profile'})}>
                                        <button className="p-link" onClick={(e) => this.onTopbarItemClick(e, 'profile')}>
                                            <span className="profile-image-wrapper">
                                            <Avatar className="topbar-profile-avatar" maxInitials={2} round={true} textSizeRatio={2} size={40} name={this.props.profileName}/>
                                            </span>
                                            <span className="topbar-item-name profile-name">{this.props.profileName}</span>
                                        </button>
                                        <ul className="fadeInDown">
                                            <li role="menuitem">
                                                <Link to="/profile/profile-controller">
                                                    <button className="p-link">
                                                        <i className="material-icons">account_circle</i>
                                                        <span>Profile</span>
                                                    </button>
                                                </Link>
                                            </li>
                                            {/* <li role="menuitem">
                                                <button className="p-link">
                                                    <i className="material-icons">verified_user</i>
                                                    <span>Privacy</span>
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button className="p-link">
                                                    <i className="material-icons">settings_application</i>
                                                    <span>Settings</span>
                                                </button>
                                            </li> */}
                                            {/* <li role="menuitem">
                                                <button className="p-link">
                                                    <i className="material-icons">exit_to_app</i>
                                                    <span>Logout</span>
                                                </button>
                                            </li> */}
                                        </ul>
                                    </li>
                                    {/* <li className={classNames({'active-topmenuitem': this.props.activeTopbarItem === 'settings'})}>
                                        <button className="p-link" onClick={(e) => this.onTopbarItemClick(e, 'settings')}>
                                            <i className="topbar-icon material-icons">settings</i>
                                            <span className="topbar-item-name">Settings</span>
                                        </button>
                                        <ul className="fadeInDown">
                                            <li role="menuitem">
                                                <button className="p-link">
                                                    <i className="material-icons">palette</i>
                                                    <span>Change Theme</span>
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button className="p-link">
                                                    <i className="material-icons">star</i>
                                                    <span>Favorites</span>
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button className="p-link">
                                                    <i className="material-icons">lock</i>
                                                    <span>Lock Screen</span>
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button className="p-link">
                                                    <i className="material-icons">wallpaper</i>
                                                    <span>Wallpaper</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </li> */}
                                    {/* <li className={classNames({'active-topmenuitem': this.props.activeTopbarItem === 'messages'})}>
                                        <button className="p-link" onClick={(e) => this.onTopbarItemClick(e, 'messages')}>
                                            <i className="topbar-icon material-icons animated swing">&#xE0C9;</i>
                                            <span className="topbar-badge animated rubberBand">5</span>
                                            <span className="topbar-item-name">Messages</span>
                                        </button>
                                        <ul className="fadeInDown">
                                            <li role="menuitem">
                                                <button className="topbar-message p-link">
                                                    <img src="assets/layout/images/avatar1.png" width="35"  alt="avatar1"/>
                                                    <span>Give me a call</span>
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button className="topbar-message p-link">
                                                    <img src="assets/layout/images/avatar2.png" width="35"  alt="avatar2"/>
                                                    <span>Sales reports attached</span>
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button className="topbar-message p-link">
                                                    <img src="assets/layout/images/avatar3.png" width="35" alt="avatar3"/>
                                                    <span>About your invoice</span>
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button className="topbar-message p-link">
                                                    <img src="assets/layout/images/avatar2.png" width="35" alt="avatar2"/>
                                                    <span>Meeting today at 10pm</span>
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button className="topbar-message p-link">
                                                    <img src="assets/layout/images/avatar4.png" width="35" alt="avatar4"/>
                                                    <span>Out of office</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </li> */}
                                    <li className={classNames({'active-topmenuitem': this.props.activeTopbarItem === 'notifications'})}>
                                        <button className="p-link" onClick={(e) => this.onTopbarItemClick(e, 'notifications')}>
                                            <i className="topbar-icon material-icons">notifications</i>
                                            <span className="topbar-badge animated rubberBand">{this.state.notificationCount}</span>
                                            <span className="topbar-item-name">Notifications</span>
                                        </button>
                                        <ul className="fadeInDown notify-width">
                                            {/* {wsNotifications.size > 0 ? <UserNotification userNotification={wsNotifications}/> : null} */}
                                            {userNotifications.reverse().map((userNotification, idx) => {
                                                return userNotification.status === "done" || userNotification.readMode == 2 ? null
                                                    : userNotification.readMode == 1 
                                                    ? <UserNotification key={idx} userNotification={userNotification} indexFilter={userNotification.id} handleDismiss={this.handleDismiss} handleRead={this.handleRead}  bgcolor={false}/>
                                                    : <UserNotification key={idx} userNotification={userNotification} indexFilter={userNotification.id} handleDismiss={this.handleDismiss} handleRead={this.handleRead}  bgcolor={true}/>
                                            })}
                                            {/* {userNotifications.size > 0 ? <UserNotification userNotification={userNotifications}/> : null} */}
                                            {/* <li role="menuitem" >
                                                <div className=" ">
                                                    <div className="p-grid">
                                                        <div className="p-col-0.5 notify-bg-info">
                                                        </div>
                                                        <div className="p-col-2">
                                                        {this.props.notifications.avatar ? 
                                                            <img src={this.props.notifications.avatar} width={40} style={{marginTop:"0.5vh"}} />
                                                            : null}
                                                        </div>
                                                        <div className="p-col-9">
                                                            <span><div className="p-w-bold">
                                                                {this.props.notifications.title}
                                                            </div></span>
                                                            <span>
                                                                <div>
                                                                    {this.props.notifications.description}
                                                                </div>                   
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="p-margin-left-200">
                                                        <Button icon="pi-md-close"  className="p-button-danger btn-s white" style={{display: 'inline', width: 'auto'}}/>
                                                        <Button icon="pi-md-arrow-forward"  className="p-button-success btn-s p-margin-left-10 white" style={{display: 'inline', width: 'auto'}}/>
                                                    </div>
                                                </div>
                                            </li> */}
                                            {/* <li role="menuitem" >
                                                <div className="notify-border">
                                                    <div className="p-w-bold">Title goes here</div>
                                                    <div >Description goes here with some specific details about the notification </div> 
                                                    <div>
                                                        <Button label="Dismiss" className="p-button-danger btn-s" style={{display: 'inline', width: 'auto'}}/>
                                                        <Button label="Go to" className="p-button-success btn-s p-margin-left-10" style={{display: 'inline', width: 'auto'}}/>
                                                    </div>
                                                </div>
                                            </li>
                                            <li role="menuitem" >
                                                <div className="notify-border">
                                                    <div className="p-w-bold">Title goes here</div>
                                                    <div >Description goes here with some specific details about the notification </div> 
                                                    <div>
                                                        <Button label="Dismiss" className="p-button-danger btn-s" style={{display: 'inline', width: 'auto'}}/>
                                                        <Button label="Go to" className="p-button-success btn-s p-margin-left-10" style={{display: 'inline', width: 'auto'}}/>
                                                    </div>
                                                </div>
                                            </li>
                                            <li role="menuitem" >
                                                <div className="notify-border">
                                                    <div className="p-w-bold">Title goes here</div>
                                                    <div >Description goes here with some specific details about the notification </div> 
                                                    <div>
                                                        <Button label="Dismiss" className="p-button-danger btn-s" style={{display: 'inline', width: 'auto'}}/>
                                                        <Button label="Go to" className="p-button-success btn-s p-margin-left-10" style={{display: 'inline', width: 'auto'}}/>
                                                    </div>
                                                </div>
                                            </li> */}


                                            {/* <li role="menuitem" >
                                                <div className='notify-card-margin notify-border'>
                                                    <strong>Title goes here</strong> <br/>
                                                    Description goes here 
                                                    <div><span style={{marginLeft:7, backgroundColor:'red', color:'white'}}>Dismiss</span><span style={{marginLeft:7, backgroundColor:'green', color:'white'}}>Go To</span></div>h  bvn
                                                </div>
                                            </li>
                                            <li role="menuitem" >
                                                <div className='notify-card-margin notify-border'>
                                                    <strong>Title goes here</strong> <br/>
                                                    Description goes here jkds sdfjklsd shfkjsf  ffseshjk sejkefh shuif sekkfhkj hgusirf  guyied hjdjkfdg jhseiu vdr iohsef hviudsr vhsuief sfv hsiuefh sdf vshfiu svcnisejrhrf s vhessiuf
                                                    <span><div><span style={{marginLeft:7, backgroundColor:'red', color:'white'}}>Dismiss</span><span style={{marginLeft:7, backgroundColor:'green', color:'white'}}>Go To</span></div></span>
                                                </div>
                                            </li>
                                            <li role="menuitem" >
                                                <div className='notify-card-margin notify-border'>
                                                    <strong>Title goes here</strong> <br/>
                                                    Description goes here 
                                                    <span style={{marginLeft:7, backgroundColor:'red', color:'white'}}>Dismiss</span>
                                                    <span style={{marginLeft:7, backgroundColor:'green', color:'white'}}>Go to</span>
                                                </div>
                                            </li> */}
                                            {/* <li role="menuitem">
                                                <button className="p-link">
                                                    <i className="material-icons">cloud_download</i>
                                                    <span>Download documents</span>
                                                </button>
                                            </li>
                                            <li role="menuitem">
                                                <button className="p-link">
                                                    <i className="material-icons">flight</i>
                                                    <span>Book flight</span>
                                                </button>
                                            </li> */}
                                        </ul>
                                    </li>
                                    <li className={classNames('search-item', {'active-topmenuitem': this.props.activeTopbarItem === 'search'})}
                                        // onClick={(e) => this.onTopbarItemClick(e, 'search')}
                                    >
                                        <span className="md-inputfield">
                                            <InputText type="text" onChange={(e) => this.onChangeInputSearch(e)} onKeyPress={this.handleKeyPress} onClick={(e) => this.onTopbarItemClick(e, 'search')} />
                                            <label style={{top: -20}}>Search</label>
                                            <i className="topbar-icon material-icons" onClick={(e) => this.handelSearch(e)}>search</i>
                                        </span>
                                        <ul className="fadeInDown p-list-search-custom" style={{paddingBottom: 0, maxHeight: 500}}>
                                            {this.state.loading ? 
                                                <li role="menuitem" className=" p-padding-all-5">
                                                    <div className="p-padding-all-5">
                                                        <ProgressSpinner style={{width: '40px', height: '40px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
                                                    </div>  
                                                </li>
                                                : ''
                                            }
                                            {this.state.campaigns && this.state.campaigns.length > 0 ? 
                                                <li role="menuitem" className="p-padding-all-5">
                                                    <div className="p-grid p-margin-bottom-15">
                                                        <div className="p-col-12">
                                                            <div className="p-padding-bottom-5" style={{borderBottom: "1px solid #eaeaea"}}>
                                                                <div className="p-grid">
                                                                    <div className="p-col-6">
                                                                        Campaigns
                                                                    </div>
                                                                    <div className="p-col-6 p-r">
                                                                        <Link to={`/campaign-management/campaigns`}className="nav-link">
                                                                            more...
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.campaigns.map(item => {
                                                            return(
                                                                <div className="p-col-12">
                                                                    <div className="p-margin-left-10">
                                                                        {item.name}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </li>
                                                : ''
                                            }
                                            {this.state.programs && this.state.programs.length > 0 ? 
                                                <li role="menuitem" className="p-padding-all-5">
                                                    <div className="p-grid p-margin-bottom-15">
                                                        <div className="p-col-12">
                                                            <div className="p-padding-bottom-5" style={{borderBottom: "1px solid #eaeaea"}}>
                                                                <div className="p-grid">
                                                                    <div className="p-col-6">
                                                                        Programs
                                                                    </div>
                                                                    <div className="p-col-6 p-r">
                                                                        <Link to={`/programs?name=${this.state.searchValue}`} className="nav-link">
                                                                            more...
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.programs.map(item => {
                                                            return(
                                                                <div className="p-col-12">
                                                                    <div className="p-margin-left-10">
                                                                        <Link to={`/programs/${item.id}`} className="nav-link">
                                                                            {item.name}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </li>
                                                : ''
                                            }
                                            {this.state.courses && this.state.courses.length > 0 ? 
                                                <li role="menuitem" className=" p-padding-all-5">
                                                    <div className="p-grid">
                                                        <div className="p-col-12">
                                                            <div className="p-padding-bottom-10" style={{borderBottom: "1px solid #eaeaea"}}>Courses</div>
                                                        </div>
                                                        {this.state.courses.map(item => {
                                                            return(
                                                                <div className="p-col-12">
                                                                    <div className="p-margin-left-10">
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-md-9">
                                                                                <div className="p-padding-top-5">{item.name}</div>
                                                                            </div>
                                                                            <div className='p-col-12 p-md-3'>
                                                                                <Link to={`/programs/${item.parentId}/courses/${item.id}/setup`} className="nav-link" >
                                                                                    <span className="pi-md-settings" title="View Course Setup"></span>
                                                                                </Link>
                                                                                <Link to={`/programs/${item.parentId}/courses/${item.id}/manage`} className="nav-link p-margin-left-5">
                                                                                    <span className="pi-md-subject" title="View Course Manage"></span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </li>
                                                : ''
                                            }
                                            {this.state.users && this.state.users.length > 0 ? 
                                                <li role="menuitem" className="p-padding-all-5">
                                                    <div className="p-grid p-margin-bottom-15">
                                                        <div className="p-col-12">
                                                            <div className="p-padding-bottom-10" style={{borderBottom: "1px solid #eaeaea"}}>
                                                                <div className="p-grid">
                                                                    <div className="p-col-6">
                                                                        Contacts
                                                                    </div>
                                                                    <div className="p-col-6 p-r">
                                                                        <Link to={`/constituent-management/contacts?name=${this.state.searchValue}`} className="nav-link">
                                                                            more...
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.users.map(item => {
                                                            return(
                                                                <div className="p-col-12">
                                                                    <div className="p-margin-left-10">
                                                                        <Link to={`/constituent-management/contacts/${item.id}`}className="nav-link">
                                                                            {item.name}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </li>
                                                : ''
                                            }
                                            {this.state.households && this.state.households.length > 0 ? 
                                                <li role="menuitem" className="p-padding-all-5">
                                                    <div className="p-grid p-margin-bottom-15">
                                                        <div className="p-col-12">
                                                            <div className="p-padding-bottom-10" style={{borderBottom: "1px solid #eaeaea"}}>
                                                                <div className="p-grid">
                                                                    <div className="p-col-6">
                                                                        Households
                                                                    </div>
                                                                    <div className="p-col-6 p-r">
                                                                        <Link to={`/constituent-management/household?name=${this.state.searchValue}`} className="nav-link">
                                                                            more...
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.households.map(item => {
                                                            return(
                                                                <div className="p-col-12">
                                                                    <div className="p-margin-left-10">
                                                                        <Link to={`/constituent-management/household/${item.id}`}className="nav-link">
                                                                            {item.name}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </li>
                                                : ''
                                            }
                                            {this.state.companies && this.state.companies.length > 0 ? 
                                                <li role="menuitem" className="p-padding-all-5">
                                                    <div className="p-grid p-margin-bottom-15">
                                                        <div className="p-col-12">
                                                            <div className="p-padding-bottom-10" style={{borderBottom: "1px solid #eaeaea"}}>
                                                                <div className="p-grid">
                                                                    <div className="p-col-6">
                                                                        Companies
                                                                    </div>
                                                                    <div className="p-col-6 p-r">
                                                                        <Link to={`/constituent-management/company?name=${this.state.searchValue}`} className="nav-link">
                                                                            more...
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.companies.map(item => {
                                                            return(
                                                                <div className="p-col-12">
                                                                    <div className="p-margin-left-10">
                                                                        <Link to={`/constituent-management/company/${item.id}`}className="nav-link">
                                                                            {item.name}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </li>
                                                : ''
                                            }
                                            {this.state.campaigns && this.state.campaigns.length === 0 && this.state.programs && this.state.programs.length === 0 
                                                && this.state.courses && this.state.courses.length === 0 && this.state.users && this.state.users.length ===0
                                                && this.state.households && this.state.households.length === 0 && this.state.departments && this.state.departments.length ===0
                                                ? 
                                                <li role="menuitem" className=" p-padding-all-5">
                                                    No options.
                                                </li>
                                                : ''
                                            }
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )
                }
            </AppContext.Consumer>
        );
    }
}

const styles = {
    userNotification: {
        backgroundColor: "grey"
    }
}